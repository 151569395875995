import firebase from "firebase/app"


var config = {
    apiKey: "AIzaSyAj0VELteG6grhDnyfk406lTOjbPGriHpg",
    authDomain: "draw-mm.firebaseapp.com",
    databaseURL: "https://draw-mm.firebaseio.com",
    storageBucket: "draw-mm.appspot.com"
};

firebase.initializeApp(config)

export default firebase