import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ProTip from './ProTip';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Participants2 from './Participants2';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
}))

export default function App() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="inherit" noWrap>
            Świąteczne losowanie!
          </Typography>
        </Toolbar>
      </AppBar>
      {/* <Typography variant="body1" align="center"> */}
        <Participants2 />
      {/* </Typography> */}
    </React.Fragment>
  );
}
