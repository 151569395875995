import firebase from "./firebase";
import "firebase/database";
import React, { Component, useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    formControl: {
        margin: 10,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 10,
    },
    separator: {
        marginTop: 20
    }
};

class Participants2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            "user": "",
            "users": [],
            "joinedUsers": [],
            "drawingStarted": false,
            "drawingFinished": false,
            "configuration": {},
            "participated": [],
            "nowDrawing": false,
            "drawn": [],
            "currentUser": "",
            "yourDrawn": "",
            "drawnText": "Wylosowałeś"
        };
    }

    setDrawingIfEnabled = () => {
        var expected = this.state.configuration && this.state.configuration.expected;
        console.log(this.state)
        if (expected && expected == this.state.joinedUsers.length) {
            this.setState({ drawingStarted: true })
            this.chooseCurrentUser();
        } else {
            this.setState({ drawingStarted: false })
        }
    }

    chooseCurrentUser = () => {
        var orderedUsers = this.state.joinedUsers.sort();
        console.log("All participants " + orderedUsers);
        var participated = this.state.participated
        if (participated.length == this.state.configuration.expected) {
            this.setState({ drawingFinished: true })
        } else {
            var leftUsers = orderedUsers.filter(n => !participated.includes(n));
            console.log("Left: " + leftUsers);
            if (leftUsers.length > 0) {
                var nextUser = leftUsers[0]
                var nowDrawing = nextUser == this.state.user
                console.log("isNowDrawing: " + nowDrawing + " , the user: " + this.state.user)
                this.setState({ nowDrawing: nowDrawing })
                this.setState({ currentUser: nextUser });
                console.log("now drawing: " + nextUser)
            } else {
                this.setState({ drawingFinished: true })
            }
        }
    }

    setListenerOnJoined = (database) => {
        var joinedRef = database.ref("joined");
        joinedRef.on("child_added", (data) => {
            var user = data.val().username
            var users = this.state.joinedUsers
            users.push(user);
            console.log("Added: " + user)
            this.setState({ joinedUsers: users }, () => {
                this.setDrawingIfEnabled()
            })

        })
        joinedRef.on("child_removed", (data) => {
            var allUsers = [];
            var tmpJoined = this.state.joinedUsers;
            var removedUser = data.val().username;
            tmpJoined.map(item => {
                if (item != removedUser) {
                    allUsers.push(item);
                }
            })
            this.setState({ joinedUsers: allUsers }, () => {
                this.setDrawingIfEnabled()
            });
            console.log("Users afer removal: " + allUsers)
        })
    }

    setListenerOnParticipated = (database) => {
        var joinedRef = database.ref("participated");
        joinedRef.on("child_added", (data) => {
            var user = data.val().username
            var drawn = data.val().drawn
            var users = this.state.participated
            var drawnUsers = this.state.drawn
            users.push(user)
            drawnUsers.push(drawn)
            console.log("Already articipated: " + user)
            this.setState({ participated: users, drawn: drawnUsers }, () => {
                this.chooseCurrentUser();
            })

        })
    }

    componentDidMount(prevProps) {
        var database = firebase.database();
        database.ref('participants').once('value').then((users) => {
            var u = users.val() || 'Anonymous';
            this.setState({ users: u });
            console.log(u)
        })
        database.ref('configuration').once('value').then((configuration) => {
            var config = configuration.val() || 'Anonymous';
            this.setState({ configuration: config });
            console.log(config)
        })
        this.setListenerOnJoined(database);
        this.setListenerOnParticipated(database);
    };

    addJoinedUser = (event) => {
        var u = event.target.value
        var drawnText = "Wylosowałeś"
        if (u.slice(-1) == 'a') {
            drawnText = "Wylosowałaś"
        }
        this.setState({ user: u, drawnText: drawnText }, function () {
            console.log("Written " + this.state.user)

            var userData = {
                username: u
            }
            firebase.database().ref().child("joined").push().set(userData);
        })
    };

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    isInPair = (user, yourDrawn) => {
        var pairs = this.state.configuration.pairs;
        return pairs[user] == yourDrawn;
    }

    draw = (leftToBeDrawn, joinedUsers, user,) => {
        var drawnIndex = this.getRandomInt(leftToBeDrawn.length);
        var yourDrawn = leftToBeDrawn[drawnIndex]
        console.log("Drawn: " + yourDrawn + ". Verifying...")
        // check if not from pair
        if (this.isInPair(user, yourDrawn)) {
            console.log(" --- Drawn a pair: " + user + "+" + yourDrawn + ". Repeating...");
            return this.draw(leftToBeDrawn, joinedUsers, user);
        } else {
            console.log(" --- Not a pair. Further verification");
        }
        
        var leftToBeDrawnAfterThisDrawn = leftToBeDrawn.filter(n => n != yourDrawn)
        var participated = [...this.state.participated];
        participated.push(user);
        var notParticipatedAfterThisDraw = joinedUsers.filter(n => !participated.includes(n));
        var repeatDrawing = false

        if (notParticipatedAfterThisDraw.length == 3) {
            //out of three left participants there's a pair and the same pair is within 
            var u = notParticipatedAfterThisDraw;
            var pair;
            if (this.isInPair(u[0], u[1])) {
                pair = [u[0], u[1]];
            } else if (this.isInPair(u[0], u[2])) {
                pair = [u[0], u[2]];
            } else if (this.isInPair(u[1], u[2])) {
                pair = [u[1], u[2]]
            }
            if (pair && leftToBeDrawnAfterThisDrawn.includes(pair[0]) && leftToBeDrawnAfterThisDrawn.includes(pair[1])) {
                repeatDrawing = true;
                console.log(" --- Found conflicting pair within 3 left participants and not drawn. Repeating...")
            } else {
                console.log(" --- No conflicing pairs found");
            }
        }
        // check if left people can draw
        if (notParticipatedAfterThisDraw.length == 1 && leftToBeDrawnAfterThisDrawn.length == 1) {
            console.log(" --- Last member verification");
            if (notParticipatedAfterThisDraw[0] == leftToBeDrawnAfterThisDrawn[0]) {
                repeatDrawing = true;
                console.log(" ----- Left " + notParticipatedAfterThisDraw[0] + " to draw " + leftToBeDrawnAfterThisDrawn[0] + ". Repeating...")
            } else if (this.isInPair(notParticipatedAfterThisDraw[0], leftToBeDrawnAfterThisDrawn[0])) {
                repeatDrawing = true
                console.log(" ----- Pair detected in last round of drawing ("+notParticipatedAfterThisDraw[0]+", "+leftToBeDrawnAfterThisDrawn[0]+"). Repeating...")
            } else {
                console.log(" ------ Last member ok")
            }
        }
        if (repeatDrawing) {
            return this.draw(leftToBeDrawn, joinedUsers, user);
        } else {
            console.log(" --- All good");
            return yourDrawn;
        }
    }

    drawHandler = (event) => {
        var drawn = this.state.drawn
        var joinedUsers = this.state.joinedUsers;
        var user = this.state.user
        var leftToBeDrawn = joinedUsers.filter(n => !drawn.includes(n));
        console.log("All not drawn: " + leftToBeDrawn);
        leftToBeDrawn = leftToBeDrawn.filter(n => n != user);
        console.log("Not drawn - user: " + leftToBeDrawn);

        var yourDrawn = this.draw(leftToBeDrawn, joinedUsers, user);

        this.setState({ yourDrawn: yourDrawn }, () => {
            console.log("Drawn: " + yourDrawn);
            var participatedData = {
                username: this.state.user,
                drawn: yourDrawn
            }
            firebase.database().ref().child("participated").push().set(participatedData);
        })
    }

    render() {
        const { classes } = this.props;

        let drawingButton;
        if (this.state.nowDrawing || this.state.yourDrawn) {
            drawingButton = (
                <React.Fragment>
                    <Typography variant="h6" className={classes.separator}>
                        Teraz Twoja kolej!
                    </Typography>
                    <Button variant="contained" color="primary" onClick={this.drawHandler} disabled={!!this.state.yourDrawn}>
                        Losuj!
                    </Button>
                </React.Fragment>
            )
        } else {
            drawingButton = <React.Fragment />
        }

        let statePanel;
        if (!this.state.drawingFinished) {
            statePanel = (
                <React.Fragment>

                    <Typography variant="h6" className={classes.separator}>
                        Losowanie zaczęte!
                    </Typography>
                    <Typography variant="body1">
                        Obecnie głosuje: {this.state.currentUser}
                    </Typography>
                </React.Fragment>
            )
        } else {
            statePanel = (
                <React.Fragment>
                    <Typography variant="h6" className={classes.separator}>Losowanie zakończone!</Typography>
                </React.Fragment>
            )
        }


        let drawingPanel;
        if (this.state.drawingStarted) {
            drawingPanel = (
                <React.Fragment>
                    {statePanel}
                    {drawingButton}
                    <Typography variant="h5" className={classes.separator}>
                        {this.state.yourDrawn ? this.state.drawnText + ': ' + this.state.yourDrawn : ''}
                    </Typography>
                </React.Fragment>
            )
        } else {
            drawingPanel = (
                <React.Fragment>
                    <Typography variant="h6" className={classes.separator}>
                        <i>Czekamy, aż wszyscy dołączą...</i>
                    </Typography>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <FormControl className={classes.formControl}>
                    <InputLabel id="participant-selector">Wybierz imię</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.user}
                        onChange={this.addJoinedUser}>
                        {this.state.users.map(function (name, index) {
                            return <MenuItem value={name} key={index}>{name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Typography variant="h6" className={classes.separator}>
                    Dołączyli:
                </Typography>
                <Typography variant="body1" >
                    {this.state.joinedUsers.map(function (name, index) {
                        return <span key={index}>{name} | </span>
                    })}
                </Typography>
                {drawingPanel}
            </React.Fragment>
        )
    }
}
Participants2.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Participants2);